import { RouteConfig } from 'vue-router';
const Bentijiaoyan = () => import('./bentijiaoyan/bentijiaoyan.vue');
const Yuanwenjiaoyan = () => import('./yuanwenjiaoyan/yuanwenjiaoyan.vue');
const Jiaoyanguanli = () => import('./jiaoyanguanli/jiaoyanguanli.vue');
const Shuxinglaiyuan = () => import('./shuxinglaiyuan/shuxinglaiyuan.vue');
import BentijiaoyanRouterChildren from './bentijiaoyan/bentijiaoyan.router';
import YuanwenjiaoyanRouterChildren from './yuanwenjiaoyan/yuanwenjiaoyan.router';
import JiaoyanguanliRouterChildren from './jiaoyanguanli/jiaoyanguanli.router';
import ShuxinglaiyuanRouterChildren from './shuxinglaiyuan/shuxinglaiyuan.router';
const routers: RouteConfig[] = [
    {
        path: 'bentijiaoyan',
        component: Bentijiaoyan,
        children: BentijiaoyanRouterChildren
    },
    {
        path: 'yuanwenjiaoyan',
        component: Yuanwenjiaoyan,
        children: YuanwenjiaoyanRouterChildren
    },
    {
        path: 'jiaoyanguanli',
        component: Jiaoyanguanli,
        children: JiaoyanguanliRouterChildren
    },
    {
        path: 'shuxinglaiyuan',
        component: Shuxinglaiyuan,
        children: ShuxinglaiyuanRouterChildren
    },
    {
        path: '*',
        redirect: 'bentijiaoyan'
    },
];
export default routers;

