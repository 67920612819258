const configuration: any = {};
/**
 * @description 根据环境配置api
 */
const getOption = (): Promise<any> => {
    return new Promise((resolve, reject) => {
        let dsObj = {};
        if (process.env.NODE_ENV === "development") {
            window.API_ROOT = "https://annotator-test.i-tcm.cn";
            // window.API_ROOT = "http://10.11.30.10:9010";
            window.qrsrc = "/common/saas-test.jpg";
            document.title = "中医药知识深加工平台";
            dsObj = {
                项目名称: "中医药知识深加工平台",
                logo: '/common/saas.png',
            };
        } else if (process.env.VUE_APP_ENV === "test") {
            window.API_ROOT = "https://annotator-test.i-tcm.cn";
            window.qrsrc = "/common/saas-test.jpg";
            document.title = "中医药知识深加工平台";
            dsObj = {
                项目名称: "中医药知识深加工平台",
                登录页名称1: "中医药知识深加工平台",
                登录页名称2: "",
                关键词: "专病",
                小程序名称: "中医药知识深加工平台",
                logo: '/common/saas.png',
            };
        } else if (process.env.VUE_APP_ENV === "validate") {
            window.API_ROOT = "https://annotator-validate.i-tcm.cn";
            window.qrsrc = "/common/saas.jpg";
            document.title = "中医药知识深加工平台";
            dsObj = {
                项目名称: "中医药知识深加工平台",
                登录页名称1: "中医药知识深加工平台",
                登录页名称2: "",
                关键词: "专病",
                小程序名称: "中医药知识深加工平台",
                logo: '/common/saas.png',
            };
        } else if (process.env.VUE_APP_ENV === "production") {
            window.API_ROOT = "https://annotator.i-tcm.cn";
            window.qrsrc = "/common/saas.jpg";
            document.title = "中医药知识深加工平台";
            dsObj = {
                项目名称: "中医药知识深加工平台",
                登录页名称1: "中医药知识深加工平台",
                登录页名称2: "",
                关键词: "专病",
                小程序名称: "中医药知识深加工平台",
                logo: '/common/saas.png',
            };
        }
        resolve(dsObj);
    });
};
export { getOption, configuration };
