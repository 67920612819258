import { RouteConfig } from 'vue-router';
const Folder = () => import('./views/folder.vue');
const FolderDetail = () => import('./views/folder-detail.vue');
const Read = () => import('./views/read.vue');
const Search = () => import('./views/search.vue');
const Mulu = () => import('./views/mulu.vue');
const GujiRead = () => import('./views/guji-read.vue');
const routers: RouteConfig[] = [
    {
        path: 'folder',
        component: Folder,
    },
    {
        path: 'folder-detail',
        component: FolderDetail,
    },
    {
        path: 'read',
        component: Read,
    },
    {
        path: 'search',
        component: Search,
    },
    {
        path: 'mulu',
        component: Mulu,
    },
    {
        path: 'guji-read',
        component: GujiRead,
    },
    {
        path: '*',
        redirect: 'folder'
    },
];
export default routers;

