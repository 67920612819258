import Vue from 'vue'
import Component from 'vue-class-component';
import App from './App.vue'
import router from './router';
import store from './store';
import Router from 'vue-router'


// echarts
import 'echarts-gl';
import VueEcharts from "vue-echarts-ts";
Vue.use(VueEcharts);

import echarts from 'echarts'
Vue.prototype.$echarts = echarts

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "../src/theme/theme.scss";
Vue.use(ElementUI);

import { Message } from 'element-ui'
//注意：这里使用的$message,所以在使用时候也是this.$message
Vue.prototype.$message = function (msg: any) {
  return Message({
    message: msg,
    duration: 1500,
    offset: 150,
  })
}
//分别对success、warning和error等样式进行设置
Vue.prototype.$message.success = function (msg: any) {
  return Message.success({
    message: msg,
    duration: 1500,
    offset: 150,
  })
}
Vue.prototype.$message.warning = function (msg: any) {
  return Message.warning({
    message: msg,
    duration: 1500,
    offset: 150,
  })
}
Vue.prototype.$message.error = function (msg: any) {
  return Message.error({
    message: msg,
    duration: 1500,
    offset: 150,
  })
}

// global_css
import './style.scss';

Vue.filter('dataFormat', (input: string, pattern = '') => {
  const dt = new Date(input);
  // 获取年月日
  const y = dt.getFullYear();
  const M = (dt.getMonth() + 1).toString().padStart(2, '0');
  const d = dt.getDate().toString().padStart(2, '0');
  const hh = dt.getHours().toString().padStart(2, '0');
  const mm = dt.getMinutes().toString().padStart(2, '0');
  const ss = dt.getSeconds().toString().padStart(2, '0');
  // 如果 传递进来的字符串类型，转为小写之后，等于 yyyy-mm-dd，那么就返回 年-月-日
  // 否则，就返回  年-月-日 时：分：秒
  if (pattern === 'yyyy/MM/dd') {
    return `${y}/${M}/${d}`;
  } else if (pattern === 'HH:mm:ss') {
    return `${hh}:${mm}:${ss}`;
  } else {
    return `${y}-${M}-${d} ${hh}:${mm}:${ss}`;
  }
});


Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate'
]);

Vue.config.productionTip = false

const originalPush: any = Router.prototype.push
Router.prototype.push = function push(location: any) {
  return originalPush.call(this, location).catch((err: any) => err)
}

new Vue({
  router,
  store,
  render: (h) => {
    return h(App);
  },
}).$mount('#app')
