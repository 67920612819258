import { RouteConfig } from 'vue-router';
const Show = () => import('./views/show.vue');
const routers: RouteConfig[] = [
    {
        path: 'show',
        component: Show,
    },
    {
        path: '*',
        redirect: 'show'
    },
];
export default routers;