import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@/plugins/axios';
import { configuration } from './setting';
Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    configuration: configuration,
    user: {},
    quanxianData: {},
    token: '',
    ifCompose: false,// 当前编辑状态
    kuList: [],// 知识库列表
    currentKu: '',// 当前知识库
    zhanghu: {},// 添加用户详情获取不到角色，从列表页携带不友好，采取store存储方式
  },
  mutations: {
    updateToken(state, token) {
      state.token = token;
      localStorage.setItem('token', token);
    },
    updateUser(state, data) {
      state.user = data;
      localStorage.setItem('user', JSON.stringify(data));
    },
    updateQuanxianData(state, data) {
      state.quanxianData = data;
    },
    updateZhanghu(state, data) {
      state.zhanghu = data;
    },
    updateIfCompose(state, ifCompose) {
      state.ifCompose = ifCompose;
    },
    updateKuList(state, data) {
      state.kuList = data;
    },
    updateCurrentKu(state, data) {
      state.currentKu = data;
    },
  },
});

export default store;
