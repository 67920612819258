import { RouteConfig } from 'vue-router';
const List = () => import('./views/list.vue');
const Compose = () => import('./views/compose.vue');
const Read = () => import('./views/read.vue');
const routers: RouteConfig[] = [
    {
        path: 'list',
        component: List,
    },
    {
        path: 'compose',
        component: Compose,
    },
    {
        path: 'read',
        component: Read,
    },
    {
        path: '*',
        redirect: 'list'
    },
];
export default routers;