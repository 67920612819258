import { RouteConfig } from 'vue-router';
const Access = () => import('../access/access.vue');
const Home = () => import('../home/home.vue');
const Manage = () => import('../manage/manage.vue');
const Check = () => import('../check/check.vue');
const Mark = () => import('../mark/mark.vue');
const Storehouse = () => import('../storehouse/storehouse.vue');
const Tupu = () => import('../tupu/tupu.vue');
import AccessRouterChildren from '../access/access.router';
import HomeRouterChildren from '../home/home.router';
import ManageRouterChildren from '../manage/manage.router';
import CheckRouterChildren from '../check/check.router';
import MarkRouterChildren from '../mark/mark.router';
import StorehouseRouterChildren from '../storehouse/storehouse.router';
import TupuRouterChildren from '../tupu/tupu.router';
const routers: RouteConfig[] = [
    {
        path: 'account',
        component: Access,
        children: AccessRouterChildren
    },
    {
        path: 'home',
        component: Home,
        children: HomeRouterChildren
    },
    {
        path: 'check',
        component: Check,
        children: CheckRouterChildren
    },
    {
        path: 'mark',
        component: Mark,
        children: MarkRouterChildren
    },
    {
        path: 'manage',
        component: Manage,
        children: ManageRouterChildren
    },
    {
        path: 'storehouse',
        component: Storehouse,
        children: StorehouseRouterChildren
    },
    {
        path: 'tupu',
        component: Tupu,
        children: TupuRouterChildren
    },
];
export default routers;

