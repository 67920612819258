import { RouteConfig } from 'vue-router';
const List = () => import('./views/list.vue');
const Detail = () => import('./views/detail.vue');
const routers: RouteConfig[] = [
    {
        path: 'list',
        component: List,
    },
    {
        path: 'detail',
        component: Detail,
    },
    {
        path: '*',
        redirect: 'list'
    },
];
export default routers;
