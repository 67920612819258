import Vue from 'vue';
import Router from 'vue-router';
import store from './store';
const Access = () => import('./views/access/access.vue');
const Main = () => import('./views/main/main.vue');
import AccessRouterChildren from './views/access/access.router';
import MainRouterChildren from './views/main/main.router';
Vue.use(Router);
/**
 * @description 通过获得localstorage来检测是否有token
 */
const checkLoggedIn = (): boolean => {
  let ifLoggedIn: boolean = false;
  const token: string | null = localStorage.getItem('token');
  if (token && typeof (token) === 'string') {
    ifLoggedIn = true;
    store.commit("updateToken", token);
  }
  return ifLoggedIn;
};
const router = new Router({
  routes: [
    {
      path: '/access',
      component: Access,
      children: AccessRouterChildren
    },
    {
      path: '/main',
      name: 'main',
      component: Main,
      children: MainRouterChildren,
      beforeEnter: (to, from, next) => {
        const ifLoggedIn = checkLoggedIn();
        if (ifLoggedIn) {
          next();
        } else {
          next('/access/login');
        }
      }
    },
    {
      path: '*',
      redirect: '/access/login',
    },
  ],
});

export default router;
