import { RouteConfig } from 'vue-router';
const Guanli = () => import('./guanli/guanli.vue');
const Biaozhu = () => import('./biaozhu/biaozhu.vue');
const Shenhe = () => import('./shenhe/shenhe.vue');
const Jieguo = () => import('./jieguo/jieguo.vue');
const Tongji = () => import('./tongji/tongji.vue');
const Schema = () => import('./schema/schema.vue');
import GuanliRouterChildren from './guanli/guanli.router';
import BiaozhuRouterChildren from './biaozhu/biaozhu.router';
import ShenheRouterChildren from './shenhe/shenhe.router';
import JieguoRouterChildren from './jieguo/jieguo.router';
import TongjiRouterChildren from './tongji/tongji.router';
import SchemaRouterChildren from './schema/schema.router';
const routers: RouteConfig[] = [
    {
        path: 'guanli',
        component: Guanli,
        children: GuanliRouterChildren
    },
    {
        path: 'biaozhu',
        component: Biaozhu,
        children: BiaozhuRouterChildren
    },
    {
        path: 'shenhe',
        component: Shenhe,
        children: ShenheRouterChildren
    },
    {
        path: 'jieguo',
        component: Jieguo,
        children: JieguoRouterChildren
    },
    {
        path: 'tongji',
        component: Tongji,
        children: TongjiRouterChildren
    },
    {
        path: 'schema',
        component: Schema,
        children: SchemaRouterChildren
    },
    {
        path: '*',
        redirect: 'guanli'
    },
];
export default routers;

