import { RouteConfig } from 'vue-router';
const Zhanghu = () => import('./zhanghu/zhanghu.vue');
const Juese = () => import('./juese/juese.vue');
import ZhanghuRouterChildren from './zhanghu/zhanghu.router';
import JueseRouterChildren from './juese/juese.router';
const routers: RouteConfig[] = [
    {
        path: 'zhanghu',
        component: Zhanghu,
        children: ZhanghuRouterChildren
    },
    {
        path: 'juese',
        component: Juese,
        children: JueseRouterChildren
    },
    {
        path: '*',
        redirect: 'zhanghu'
    },
];
export default routers;

